<template>
  <div class="flex flex-col gap-4">
    <section class="box p-4 flex justify-between">
      <div class="flex flex-col">
        <span class="font-bold">Numero de radicado:</span>
        <span class="text-lg">{{ getProcesoJudicial.numeroRadicado }}</span>
      </div>

      <div class="flex flex-col">
        <span class="font-bold">Información del juzgado:</span>
        <span class="text-lg">{{ getProcesoJudicial.juzgadoProcesosJudiciales.nombre }}</span>
      </div>

      <div class="flex flex-col justify-center gap-2">
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"/>
          </svg>
          <span class="font-bold">Fecha Reparto: </span>
          <span>{{ $h.formatDate(getProcesoJudicial.fechaReparto) }}</span>
        </div>
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 p-1 rounded-full" viewBox="0 0 20 20"
               fill="currentColor">
            <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"/>
          </svg>
          <span class="font-bold">Fecha Notificación: </span>
          <span>{{ $h.formatDate(getProcesoJudicial.fechaNotificacion) }}</span>
        </div>
      </div>
    </section>

    <section class="box p-4 flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <div class="flex justify-between items-center">
          <span class="font-bold">Demandantes</span>
          <Button label="Agregar Demandante" icon="pi pi-plus" @click="$router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos.agregar-demandante'})"/>
        </div>
        <template v-if="accionantesProcesosJudiciales.length > 0">
          <Accordion :activeIndex="activeIndexDemandantes">
            <template v-for="(item, key) in accionantesProcesosJudiciales" :key="key">
              <AccordionTab :header="item.accionante.fullname">
                <div class="flex flex-col text-sm gap-2">
                  <span><span class="font-bold">Nombre Completo: </span>{{ item.accionante.fullname }}</span>
                  <span><span class="font-bold">Documento: </span>{{ item.accionante.docTipoId }}. {{ item.accionante.numeroIdentificacion }}</span>
                  <span><span class="font-bold">Telefono: </span>{{ item.accionante.telefono }}</span>
                  <span><span class="font-bold">Correo: </span>{{ item.accionante.correo }}</span>
                  <span><span class="font-bold">Direccion: </span>{{ item.accionante.localizacion }}</span>
                  <span><span class="font-bold">Representante Legal: </span>{{ item.accionante.representanteLegal }}</span>
                  <span><span class="font-bold">Representante Legal Contacto: </span>{{ item.accionante.representanteLegalCont }}</span>
                  <span><span class="font-bold">Nombre Apoderado: </span>{{ item.accionante.apoderadoNombre }}</span>
                  <span><span class="font-bold">Tipo Documento: </span>{{ item.accionante.apoderadoTipoDoc }}</span>
                  <span><span class="font-bold">Documento: </span>{{ item.accionante.apoderadoDocumento }}</span>
                  <span><span class="font-bold">Tarjeta Profesional: </span>{{ item.accionante.apoderadoTarjeta }}</span>
                  <span><span class="font-bold">Correo: </span>{{ item.accionante.apoderadoCorreo }}</span>
                  <span><span class="font-bold">Telefono: </span>{{ item.accionante.apoderadoTelefono }}</span>
                </div>
              </AccordionTab>
            </template>
          </Accordion>
        </template>
        <template v-else>
          <span class="font-bold text-red-500">No tiene demandantes</span>
        </template>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex justify-between items-center">
          <span class="font-bold">Demandados</span>
          <Button label="Agregar Demandado" icon="pi pi-plus" @click="$router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos.agregar-demandado'})"/>
        </div>
        <template v-if="accionadosProcesosJudiciales.length > 0">
          <Accordion :activeIndex="activeIndexDemandados">
            <template v-for="(item, key) in accionadosProcesosJudiciales" :key="key">
              <AccordionTab :header="item.accionado.fullname">
                <div class="flex flex-col text-sm gap-2">
                  <span><span class="font-bold">Nombre Completo: </span>{{ item.accionado.fullname }}</span>
                  <span><span class="font-bold">Documento: </span>{{ item.accionado.docTipoId }}. {{ item.accionado.numeroIdentificacion }}</span>
                  <span><span class="font-bold">Telefono: </span>{{ item.accionado.telefono }}</span>
                  <span><span class="font-bold">Correo: </span>{{ item.accionado.correo }}</span>
                  <span><span class="font-bold">Direccion: </span>{{ item.accionado.localizacion }}</span>
                  <span><span class="font-bold">Representante Legal: </span>{{ item.accionado.representanteLegal }}</span>
                  <span><span class="font-bold">Nombre Apoderado: </span>{{ item.accionado.apoderadoNombre }}</span>
                  <span><span class="font-bold">Tipo Documento: </span>{{ item.accionado.apoderadoTipoDoc }}</span>
                  <span><span class="font-bold">Documento: </span>{{ item.accionado.apoderadoDocumento }}</span>
                  <span><span class="font-bold">Tarjeta Profesional: </span>{{ item.accionado.apoderadoTarjeta }}</span>
                  <span><span class="font-bold">Correo: </span>{{ item.accionado.apoderadoCorreo }}</span>
                  <span><span class="font-bold">Telefono: </span>{{ item.accionado.apoderadoTelefono }}</span>
                </div>
              </AccordionTab>
            </template>
          </Accordion>
        </template>
        <template v-else>
          <span class="font-bold text-red-500">No tiene demandados</span>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import ProcesosJudicialesStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/procesosJudiciales.store'
import { useRoute } from 'vue-router'

export default {
  name: 'info-sujetos',
  setup () {
    const activeIndexDemandantes = ref(null)
    const activeIndexDemandados = ref(null)
    const route = useRoute()

    const getProcesoJudicial = computed(() => ProcesosJudicialesStore.getters.getProcesoJudicial)
    const accionantesProcesosJudiciales = computed(() => ProcesosJudicialesStore.getters.getProcesoJudicial.accionantesProcesosJudiciales)
    const accionadosProcesosJudiciales = computed(() => ProcesosJudicialesStore.getters.getProcesoJudicial.accionadosProcesosJudiciales)

    onMounted(() => {
      ProcesosJudicialesStore.dispatch('actionProcesoJudicial', route.params.id)
    })

    return {
      getProcesoJudicial,
      accionantesProcesosJudiciales,
      accionadosProcesosJudiciales,
      activeIndexDemandantes,
      activeIndexDemandados
    }
  }
}
</script>

<style scoped>

</style>
